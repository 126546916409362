import { DecryptToken } from "./decrypt"

const token = DecryptToken();

const getLoggedinUser = () => {
  const user = token;
  if (!user) {
    return null;
  } else {
    return user;
  }
};

export {getLoggedinUser};