import React from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Widgets = (props) => {
    const {order, roll, flaw, millimeter } = props;
  const { t } = useTranslation('dashboard'); // Using the 'dashboard' namespace

    return (
        <React.Fragment>
            {/* ORDER BOX */}
            <Col xl={3} md={6} >
                <Card className="card-animate">
                    <CardBody>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{props.t('orderCount')}</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" data-target="559.25">
                                    <CountUp
                                        start={0}
                                        separator=","
                                        end={order}
                                        duration={4}
                                    />
                                </span></h4>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                                <span className={"avatar-title rounded fs-3 bg-soft-success" }>
                                    <i className={`text-success bx bx-shopping-bag`}></i>
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            
            {/* ROLL BOX */}
            <Col xl={3} md={6} >
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{props.t('rollCount')}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            separator=","
                                            end={roll}
                                            duration={4}
                                        />
                                    </span></h4>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={"avatar-title rounded fs-3 bg-soft-warning" }>
                                        <i className={`text-warning bx bx-plus-circle`}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
            </Col>

            {/* FLAW BOX */}
            <Col xl={3} md={6} >
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{props.t('flawCount')}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            separator=","
                                            end={flaw}
                                            duration={4}
                                        />
                                    </span></h4>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={"avatar-title rounded fs-3 bg-soft-danger" }>
                                        <i className={`text-danger bx bx-error-alt`}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
            </Col>

            {/* MILLIMETER BOX */}
            <Col xl={3} md={6} >
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{props.t('totalMeter')}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            separator=","
                                            end={roll}
                                            duration={4}
                                        />
                                    </span></h4>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={"avatar-title rounded fs-3 bg-soft-info" }>
                                        <i className={`text-info bx bx-tachometer`}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
            </Col>

        </React.Fragment>
    );
};

export default Widgets;