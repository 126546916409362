import axios from "axios";
import { DecryptToken } from "./helpers/decrypt";

const token = DecryptToken();

const GetUser = async (login) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/auth/user/`,
            headers: {
                'Authorization': login ? login : token
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    }

};

const GetAllUsers = (next=null) => {
    const url = next ? next : `${process.env.REACT_APP_API_URL}/user/`;
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            // url: `${process.env.REACT_APP_API_URL}/user/`,
            url: url,
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });

    try {
        const response = api;
        return response;
    }
    catch (error) {
        return error;
    }

};

const GetAvailableUser = async (next=null) => {
    const url = `${next}&machine=null` ? next : `${process.env.REACT_APP_API_URL}/user/?machine=null`;
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            // url: `${process.env.REACT_APP_API_URL}/user/`,
            url: url,
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    }

}

const setUserMachine = (machine_id, user_id) => {
        const api = new Promise((resolve, reject) => {
            axios({
                method: 'patch',
                url: `${process.env.REACT_APP_API_URL}/user/${user_id}/`,
                headers: {
                    'Authorization': token
                },
                data: {
                    "machine_id": machine_id
                }
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
        
        try {
            const response = api;
            return response;
        }
        catch (error) {
            return error;
        }
};


const removeUserMachine = (user_id) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/user/${user_id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                "machine_id": null
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });

    try {
        const response = api;
        return response;
    }
    catch (error) {
        return error;
    }
} 





export {GetUser, GetAllUsers, setUserMachine, removeUserMachine, GetAvailableUser};