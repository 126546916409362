import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";

const token = DecryptToken();

const ReportTypeDropdown = async () => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/report/dropdown/`,
            headers: {
                'Authorization': token
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    }
    );
  
    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    }
  };

const ExcelReport = async (filters) => {
    // filter flaws according to given filters
    function jsonToQueryString(json) {
        return Object.keys(json)
            .map(key => json[key] && `${key}=${json[key]}`)
            .join('&');
    }

    const params = filters && jsonToQueryString(filters);

    const url = `${process.env.REACT_APP_API_URL}/report/?${params}`

    const api = new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url:  url,
            headers: {
                'Authorization': token
            },
            responseType: 'blob',
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

const SummaryExcelReport = async (filters) => {
    // filter flaws according to given filters
    function jsonToQueryString(json) {
        return Object.keys(json)
            .map(key => json[key] && `${key}=${json[key]}`)
            .join('&');
    }

    const params = filters && jsonToQueryString(filters);

    const url = `${process.env.REACT_APP_API_URL}/report/summary/?${params}`

    const api = new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url:  url,
            headers: {
                'Authorization': token
            },
            responseType: 'blob',
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

// const DefaultExcelReport = async (orderId) => {
//     const url = `${process.env.REACT_APP_API_URL}/excel/${orderId}/default/`

//     const api = new Promise((resolve, reject) => {
//         axios({
//             method: 'GET',
//             url:  url,
//             headers: {
//                 'Authorization': token
//             },
//             responseType: 'blob',
//         })
//         .then((response) => {
//             resolve(response);
//         })
//         .catch((error) => {
//             reject(error);
//         });
//     });

//     try {
//         const response = await api;
//         return response;
//     }
//     catch (error) {
//         return error;
//     };
// }

export { ReportTypeDropdown, ExcelReport, SummaryExcelReport};
