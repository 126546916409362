import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { GetRollMetric } from "../../api/RollMetric/RollMetric";
import { GetRollFlaw } from "../../api/roll/Roll";
import { DecryptToken } from "../../api/helpers/decrypt";
import { isEmptyArray } from "formik";


const FlawMap = (props) => {
    const {flaws, flawWColor, currentRoll, getClearImage} = props;
    const [flaw, setFlaw] = useState(null);
    const [height, setHeight] = useState(1);  // flaw map height
    const [width, setwidth] = useState(1);  // flaw map width

    const [modal, setModal] = useState(false);
    const [flawDetail, setFlawDetail] = useState({});
    const display_width = 400
    const display_height = 5000
    const pixelOfTenMeter = 10000 * (1200 / 273); // Pixel value of 10 meters
    const pixelOfOneMeter = 1000 * (1200 / 273); // Pixel value of 1 meters
    const pixelOfHalfMeter = 500 * (1200 / 273); // Pixel value of 50 centimeters
    const pixelOfTenCentiMeter = 100 * (1200 / 273); //Pixel value of 10 centimeters

    const toggle = (data) => {
        try{
            setFlawDetail(data);
        }
        catch(e){
            // console.log(e);
        }
        finally{
            setModal(!modal);
        };
    }

    const callRollMetric = async () => {
        // call the roll metric api
        // if the roll metric height is bigger than the flaw map height, update the flaw map height
        if (!isEmptyArray(flaw)){
            const response = await GetRollMetric(currentRoll.id);
            const yCoordinates = flaw.map(item => parseInt(item.flaw_coordinate.y));  // get all y coordinates of the flaws
            const xCoordinates = flaw.map(item => parseInt(item.flaw_coordinate.x));  // get all x coordinate of flaw
            const maxYCoordinate = Math.max(...yCoordinates);  // get the max y coordinate of the flaws
            const maxXCoordinate = Math.max(...xCoordinates);  // get the max x coordinate of the flaws
            // const yCoordinatesMillimeter = flaw.map(item => parseInt(item.millimeter * (1200 / 273)));  // get all y coordinates of the flaws in millimeter and convert pixel
            // const xCoordinatesMillimeter = flaw.map(item => parseInt(item.millimeter_width * (1200 / 273))); // get all x coordinate of flaw in millimeter and convert pixel
            // const maxYCoordinateMillimeter = Math.max(...yCoordinatesMillimeter);  // get the max y coordinate of the flaws 
            // const maxXCoordinateMillimeter = Math.max(...xCoordinatesMillimeter);  // get the max x coordinate of the flaws
            const height = parseInt(response.data.height);  // get the roll metric height
            const width = parseInt(response.data.width)  // get roll metric width 
 
            if (height < maxYCoordinate) {
                setHeight(maxYCoordinate);
            }
            else {
                setHeight(height);
            }

            if (width < maxXCoordinate) {
                setwidth(maxXCoordinate);
            }
            else {
                setwidth(width);
            }
        };
    };

    useEffect(() => {
        // callRollFlaw();
        // update height of the flaw map according to the roll metric height
        if (currentRoll != null){
            setTimeout(() => {
                callRollMetric();
            }, 1000);
        }
    }, [flaw]);


    useEffect(() => {
        try {
            // check the flaws data coming from the api
            // if it is not empty, set the flaw state
            if (!isEmptyArray(flaws)){
                setFlaw(flaws.results);
            }
        }
        catch  (e) {
            console.log(e);
        }

    }, [flaws]);

    const handleScroll = (event) => {
        const scrollTop = event.target.scrollTop; // Get scroll position of the second div
        const scaleDiv = document.getElementById("scaleDiv"); // get the reference of scale div
        scaleDiv.scrollTop = scrollTop; // Set the scroll position of the scale div
    };

    const handleDownload = () => {
        if (modal && flawDetail && flawDetail.image) {
            const imageUrl = `${process.env.REACT_APP_API_URL}${flawDetail.image}`;
            fetch(imageUrl)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `party_${flawDetail.order}_roll_${flawDetail.roll}_flaw_${flawDetail.flaw_count_of_roll}.jpeg`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.error('Error downloading image:', error);
                });
        }
    };


    return (
        <Container fluid>
            {/* MODALS */}
            {/* create a modal for show the details of the flaw */}
            <Modal isOpen={modal}
                toggle={toggle}
                centered={true}
                size={"xl"}>
                <ModalHeader toggle={toggle} className="px-5 ms-3 text-uppercase">
                    <h4>{props.t('flawDetails')}</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-6" style={{ display: 'flex', justifyContent:'center', alignItems: 'center'}} >
                            { flawDetail && flawDetail.flaw_coordinate &&
                                <div >
                                    <img id={`flaw_image_${flawDetail.id}`}
                                        src={modal && `${process.env.REACT_APP_API_URL}${getClearImage(flawDetail.image)}`} alt="flaw_image"
                                        loading="lazy"
                                        style={{
                                            width: '100%',
                                            maxWidth: '100%',
                                            borderRadius: '3px'
                                        }}
                                    />
                                </div>
                            }

                        </div>
                        <div className="col-md-6 text-center mt-4 fs-5">
                            <div className="row pt-3">
                                <div className="col-md-6 text-start">
                                    <p className='fs-5'><b>{props.t('flawType')}:</b></p>
                                </div>
                                <div className="col-md-6 text-start">
                                    <p className='fw-bold fs-5'>{modal && flawWColor[flawDetail.flaw_type_code].name }</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 text-start">
                                    <p className='fs-5'><b>{props.t('flawWidth')}:</b></p>
                                </div>
                                <div className="col-md-6 text-start">
                                    <p className='fw-bold'>{modal && flawDetail.flaw_size.width} <span className='text-muted'>{props.t('millimeter')}</span></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 text-start">
                                    <p className='fs-5'><b>{props.t('flawHeight')}:</b></p>
                                </div>
                                <div className="col-md-6 text-start">
                                    <p className='fw-bold'>{modal && flawDetail.flaw_size.height} <span className='text-muted'>{props.t('millimeter')}</span></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 text-start">
                                    <p className='fs-5'><b>{props.t('flawPlace')}:</b></p>
                                </div>
                                <div className="col-md-6 text-start">
                                    <p className='fw-bold'>{modal && flawDetail.millimeter / 1000} <span className='text-muted'>{props.t('meter')}</span></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 text-start">
                                    <p className='fs-5'><b>{props.t('flawPlaceWidth')}:</b></p>
                                </div>
                                <div className="col-md-6 text-start">
                                    <p className='fw-bold'>{modal && (flawDetail.millimeter_width === null ? "-" : flawDetail.millimeter_width / 10)} <span className='text-muted'>{flawDetail.millimeter_width === null ? "" : props.t('centimeter')}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {/* END OF MODALS */}

            {/* <Card style={{minHeight:'500px', maxHeight:'500px'}}> */}
            <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-row">
                    <div className="p-0  overflow-auto d-flex justify-content-start">
                        <svg width="35" height="10" style={{ backgroundColor: 'white' }}>
                        </svg>
                    </div>
                    <div className="p-0  overflow-auto d-flex justify-content-start">
                        <svg width={display_width+20} height="10" style={{ backgroundColor: 'white' }}>
                            {/* Scale lines x (every 50 centimeters) */}
                            {Array.from({ length: Math.floor(width / pixelOfHalfMeter) + 1 }).map((_, index) => {
                                const x_ratio = display_width / width
                                let line_width = pixelOfHalfMeter * x_ratio

                                const x_position = index * line_width; // Adjust x-position every 50 centimeters

                                if (index === 0) return null;

                                return (
                                    <g key={index}>
                                        <text x={x_position - 10} y="8" fontSize="10" fill="rgba(157, 109, 34, 0.4)">
                                            {index * 50} cm
                                        </text>
                                    </g>
                                );
                            })}
                        </svg>
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="p-0  overflow-auto d-flex justify-content-start">
                        <svg width="35" height="8" style={{ backgroundColor: 'white' }}>
                        </svg>
                    </div>
                    <div className="p-0  overflow-auto d-flex justify-content-start scrollable-secret-div" style={{ minHeight: '8px', maxHeight: '8px' }}>
                        <svg width={display_width+20} height="9" style={{ backgroundColor: '#9d6d222e' }}>
                            {/* Scale lines x (every 50 centimeters) */}
                            {Array.from({ length: Math.floor(width / pixelOfHalfMeter) + 1 }).map((_, index) => {
                                const x_ratio = (display_width+20) / width
                                let line_width = pixelOfHalfMeter * x_ratio

                                const x_position = index * line_width; // Adjust x-position every 50 centimeters

                                if (index === 0) return null;

                                return (
                                    <g key={index}>
                                        <line x1={x_position} y1="1" x2={x_position} y2="8" stroke="rgba(157, 109, 34, 0.4)" strokeWidth="2" />
                                    </g>
                                );
                            })}
                            {/* Scale lines x (every 10 centimeters) */}
                            {Array.from({ length: Math.floor(width / pixelOfTenCentiMeter) + 1 }).map((_, index) => {
                                const x_ratio = (display_width+20) / width
                                let line_width = pixelOfTenCentiMeter * x_ratio

                                const x_position = index * line_width; // Adjust x-position every 10 centimeters

                                if (index === 0) return null;

                                return (
                                    <g key={index}>
                                        <line x1={x_position} y1="1" x2={x_position} y2="5" stroke="rgba(157, 109, 34, 0.4)" strokeWidth="1,5" />
                                    </g>
                                );
                            })}
                        </svg>
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div id="scaleDiv" className="p-0  overflow-auto d-flex justify-content-start flex-start overflow-hidden" style={{ minHeight: '500px', maxHeight: '500px' }}>
                        <svg id="canvas" height={display_height} width="35" style={{ backgroundColor: 'white' }} >
                            {/* Scale lines y (every 10 meters) */}
                            {Array.from({ length: Math.floor(height / pixelOfTenMeter) + 1 }).map((_, index) => {
                                const y_ratio = display_height / height
                                let line_height = pixelOfTenMeter * y_ratio

                                const y_position = index * line_height; // Adjust y-position every 10 meters

                                if (index === 0) return null;

                                return (
                                    <g key={index}>
                                        <text x="0" y={y_position + 2} fontSize="10" fill="rgba(157, 109, 34, 0.4)">
                                            {index * 10}m
                                        </text>
                                    </g>
                                );
                            })}
                        </svg>
                    </div>
                    <div className="p-0  overflow-auto d-flex justify-content-start" style={{ minHeight: '500px', maxHeight: '500px' }} onScroll={handleScroll}>
                        <svg id="canvas" height={display_height} width={display_width+20} style={{ backgroundColor: '#9d6d222e', width: display_width+20 }} >
                            {flaw && flaw.map((item) => {
                                const flaw_type = item.flaw_type_code;
                                const x = item.flaw_coordinate.x;
                                const y = item.flaw_coordinate.y;
                                const y_pixel = item.millimeter * (1200 / 273)
                                const x_pixel = item.millimeter_width * (1200 / 273)

                                const x_ratio = display_width / width
                                const y_ratio = display_height / height


                                return (
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        key={item.id}
                                        x={isNaN(x) ? 1 : x * x_ratio}
                                        y={isNaN(y) ? 1 : y * y_ratio}
                                        width={20}
                                        height={20}
                                        fill={flawWColor[flaw_type] ? flawWColor[flaw_type].color : 'orange'}
                                        stroke="black"
                                        cursor={"pointer"}
                                        onClick={() => toggle(item)}
                                        viewBox="0 0 52 52"
                                        enableBackground="new 0 0 52 52"
                                    >
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z"></path>
                                        </g>
                                    </svg>

                                );
                            })}
                            {/* Scale lines y (every 10 meters) */}
                            {Array.from({ length: Math.floor(height / pixelOfTenMeter) + 1 }).map((_, index) => {
                                const y_ratio = display_height / height
                                let line_height = pixelOfTenMeter * y_ratio

                                const y_position = index * line_height; // Adjust y-position every 10 meters

                                if (index === 0) return null;

                                return (
                                    <g key={index}>
                                        <line x1="0" y1={y_position} x2="7" y2={y_position} stroke="rgba(157, 109, 34, 0.4)" strokeWidth="2" />
                                    </g>
                                );
                            })}
                            {/* Scale lines y (every 1 meters) */}
                            {Array.from({ length: Math.floor(height / pixelOfOneMeter) + 1 }).map((_, index) => {
                                const y_ratio = display_height / height
                                let line_height = pixelOfOneMeter * y_ratio

                                const y_position = index * line_height; // Adjust y-position every 1 meters

                                if (index === 0) return null;

                                return (
                                    <g key={index}>
                                        <line x1="0" y1={y_position} x2="4" y2={y_position} stroke="rgba(157, 109, 34, 0.4)" strokeWidth="1,5" />
                                    </g>
                                );
                            })}
                        </svg>
                    </div>
                </div>
            </div>
            
            {/* </Card> */}
        </Container>
    )
}

export default FlawMap;