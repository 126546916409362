import axios from "axios";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DecryptToken } from "../../../api/helpers/decrypt";

const token = DecryptToken();

async function getMachineService(){

    const api = new Promise((resolve, reject) => {

        try{
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/machineservice/?limit=1000`,
                headers: {
                    Authorization: token
                }
            }).then((response) => {
                resolve(response.data.results);
            }).catch((error) => {
                reject(error)
            });
        }
        catch (error){
            console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
      } catch (error) {
        return {
            error: error.message
        }; // Handle the error appropriately or return a default value.
      }
};


// Create an async function that fetches the data from the API
const fetchMachineService = async () => {
  const data = await getMachineService();
  return data;
};

// Step 3: Use createAsyncThunk to define an action that handles the async operation
export const fetchMachineServiceAsync = createAsyncThunk(
  'machineService/fetchMachineService',
  fetchMachineService
);

const machineServiceSlice = createSlice({
  name: 'machineService',
  initialState: {
    datas: [], // Initialize with an empty array
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineServiceAsync.pending, (state) => {
        // Handle loading state if needed
      })
      .addCase(fetchMachineServiceAsync.fulfilled, (state, action) => {
        // Handle successful data fetch
        state.datas = action.payload;
      })
      .addCase(fetchMachineServiceAsync.rejected, (state) => {
        // Handle error state if needed
      });
  },
});
export default machineServiceSlice.reducer;

// Step 4: Create a separate async function for fetching the data initially
export const initializeMachineService = () => async (dispatch) => {
  try {
    await dispatch(fetchMachineServiceAsync());
  } catch (error) {
    // Handle error if needed
  }
};


