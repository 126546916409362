import React from "react";
import { Link } from "react-router-dom";
import { DecryptUserInfo } from "../api/helpers/decrypt";

const NotFoundPage = () => {
    const status = DecryptUserInfo().status;

    return (
        <div className="page-content" style={{minHeight:'100vh', overflow:'unset', backgroundColor:'black'}}>
            <div className="text-center text-light"
                 style={{
                    // { position:'absolute', 
                    //   top:'45%', 
                    //   left:'50%',
                    //   transform: 'translate(-50%, -45%)',
                 }} >
                    <p className=" fw-bold" 
                        style={{fontSize:'300px', position:'absolute', left:'50%', transform:'translate(-50%, -40%)', zIndex:'0', color:'#878a993d'}}>404</p>
                <div className='message' style={{marginTop: '10%', zIndex:'100', position:'relative'}}>
                    <h1 className="text-light fw-bolder">NOT FOUND</h1>
                    <p className="text-light">Böyle bir sayfa bulamadık</p>
                    {
                        status == 'superadmin' || status == 'admin' ? 
                        <Link to={"/"} className="btn btn-soft-warning">Anasayfaya Dön</Link>
                        :
                        <Link to={"/order"} className="btn btn-soft-warning">Anasayfaya Dön</Link>
                    
                    }
                </div>
                 
            </div>
        </div>
    )
}

export default NotFoundPage;