import Cookies from "js-cookie";


const DecryptToken = () => {
    // Decrypt token
    try {
        const encrypt_token = Cookies.get('token');
        return encrypt_token;
    }
    catch (error) {
        return null;
    }
};

const DecryptUserInfo = () => {
    // Decrypt
    try {
        const data = JSON.parse(Cookies.get('info'));
        return data;
    }
    catch (error) {
        return null;
    }
};


export { DecryptToken, DecryptUserInfo };
