import React from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import { publicRoutes, privateRoutes, topSecretRoutes } from './allRoutes.js';
import Layout from '../Layout/index.js';
import { AuthProtected } from './AuthProtected.js';
import NotFoundPage from '../pages/404.js';
import { AdminProtected } from './AdminProtected.js';

const Index = () => {

    return (
        <BrowserRouter>
            <Routes>

                <Route path={'/auth'} >
                    {publicRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={route.component}
                            key={idx}
                            exact={true}
                        />
                    ))};
                </ Route>


                <Route>
                    {privateRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <AuthProtected>
                                    <Layout>{route.component}</Layout>
                                </AuthProtected>}
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Route>
                
                <Route>
                    {topSecretRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <AdminProtected route={route}>
                                    <Layout>{route.component}</Layout>
                                </AdminProtected>}
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Route>

                <Route path="*" element={<NotFoundPage />} />
                    
            </Routes>
        </BrowserRouter>
    );
};

export default Index;