import axios from "axios";
import Cookies from "js-cookie";

const Login = async (username, password) => {

    const api = new Promise((resolve, reject) => {

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/auth/login/`,
            data: {
                "username": username,
                "password": password
            }
        }).then((response) => {
            const token = response.data.key 
            Cookies.set('token', `Token ${token}`, { expires: 7 });

            resolve(response);
            // redirect to dashboard
        }).catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

export default Login;