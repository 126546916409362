import axios from "axios";
import { DecryptToken } from "../helpers/decrypt";

const token = DecryptToken();

const setMachinePermission = async (user_id, machine_id, started_at, expired_at) => {
    /*
        * Give selected operator to access on selected machine service.
        * You should get user_id and machine_id params from form.
        * app_label, model_name and, permission_level can not changable.
    */
    const api = new Promise((resolve, reject) => {
        try {

            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/machineservice/permission/`,
                headers: {
                    Authorization: token
                },
                data: {
                    user: user_id,
                    machineservice: machine_id,
                    started_at: started_at,
                    expired_at: expired_at
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            // console.log(error);
        };
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};


const getMachinePermission = async (machine_id) => {
    /*
        * Get selected machine service permissions
    */
    const api = new Promise((resolve, reject) => {
        try {

            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/machineservice/${machine_id}/permission/`,
                headers: {
                    Authorization: token
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            // console.log(error);
        };
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
} 

const getAllMachinePermission = async (next=null) => {
    /*
        * Get selected machine service permissions
    */
    const endpoint = next ? next : `${process.env.REACT_APP_API_URL}/machineservice/permission/`;
    const api = new Promise((resolve, reject) => {
        try {

            axios({
                method: 'get',
                url: endpoint,
                headers: {
                    Authorization: token
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            // console.log(error);
        };
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    };
};

const removeMachinePermission = async (machine_id) => {
    const api = new Promise((resolve, reject) => {
        // this function stop spesific machine
        axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_API_URL}/machineservice/${machine_id}/permission/`,
            headers: {
                Authorization: token
            }
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
    } catch (error) {
        return error
    }
};

export {setMachinePermission, getMachinePermission, getAllMachinePermission, removeMachinePermission};