
import React, { useEffect, useMemo, useState } from 'react';
import TableContainer from '../../Components/Common/TableContainerReactTable';
import { Card, CardBody, CardHeader, Row, Col} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { initializeMachineService } from '../../Components/Hooks/slices/machineApi';
import { getMachines, getMachineService } from '../../api/machine/Machine';


const MachineTable = (props) => {
    const [machineService, setMachineService ] = useState([]);
    const [isReady, setIsReady] = useState(false);

    const fetchMachine = async (next=null) => {
        // fet all machines from backend
        const response = await getMachineService(next);
        const data = response.data;
        // assign data to machine state
        setMachineService(prevMachineService => [...prevMachineService, ...data.results]);
        if (data.next){
            fetchMachine(data.next);
        }
        else {
            setIsReady(true);
        }
    }

    useEffect(() => {
        // fetch all machines from backend
        fetchMachine();
    }, []);

    
    const searchTable = isReady && machineService.reverse().map((item) => { 
        const data = {
            "id": item.machine.id,
            "name": item.machine.name,
            "status": `${item.status ? 'run' : 'stop'}`,
            "order": item.order,
            "roll": item.roll,
            "created_at": item.created_at,
            "updated_at": item.updated_at
        }
        return data 
    });
      
    const columns = useMemo(
        () => [
        {
            Header: "ID",
            accessor: (cellProps) => {
            return (
                <span className="fw-semibold">{cellProps.id}</span>
            )
            },
            disableFilters: true,
            filterable: false,
        },
    
        {
            Header: props.t('machineName'),
            accessor: "name",
            disableFilters: true,
            filterable: false,
        },        
        {
            Header: props.t('machineStatus'),
            accessor: (cellProps) => {
                return (
                    {
                    'run': <span className="badge bg-soft-success text-success fs-7">{props.t('run')}</span>,
                    'stop': <span className="badge bg-soft-danger text-danger fs-7">{props.t('stop')}</span>,
                    }[cellProps.status] || null
                )
            },
            disableFilters: true,
            filterable: false,
        },
        {
            Header: props.t('partyNo'),
            accessor: (cellProps) => {
                const forward = cellProps.order && `/order/${cellProps.order.id}`
                return (
                    cellProps.order === null ? <span className="badge bg-soft-warning text-warning fs-7">{props.t('noOrder')}</span> :
                    <span className="badge bg-soft-primary text-primary fs-6" id='order-forward'>
                        <a href={forward}>
                            {cellProps.order.party_no}
                        </a>
                    </span>
                )},
            disableFilters: true,
            filterable: false,
        },
        ],
        [props.t]
    );
      
    return (
        <React.Fragment >
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <h4 className='text-uppercase mb-0' >{props.t('machineStatus')}</h4>
                        </CardHeader>
                        <CardBody>
                            <TableContainer
                                columns={(columns || [])}
                                data={(searchTable || [])}
                                isPagination={true}
                                flaw={true}
                                isGlobalFilter={true}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={5}
                                className="custom-header-css table align-middle table-nowrap"
                                tableClassName="table-centered align-middle table-nowrap mb-0"
                                theadClassName="text-muted table-light"
                                SearchPlaceholder='Search...'
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment >
    );
}; 
export default MachineTable;