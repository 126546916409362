import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { useProfile } from "../Hook/UserHooks";
import { DecryptToken, DecryptUserInfo } from "../api/helpers/decrypt";


const AdminProtected = (props) => {
  const { route } = props;

  let status;
  try {
    status = DecryptUserInfo().status;
  }
  catch (error) {
    return (
      <Navigate to={{ pathname: "/auth/login", state: { from: props.location } }} />
    );
  }

  // console.log(status, route.path === "/");
  if (status === "user" & route.path === "/" ){
      return (
          <Navigate to={{ pathname: "/order", state: { from: props.location } }} />
      );
  }
  else if ((status === "superadmin" & route.path === "/" )) {
    return (
      <Navigate to={{ pathname: "/dashboard", state: { from: props.location } }} />
  );
  }

  return <>{props.children}</>;
};

const AdminAccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AdminProtected, AdminAccessRoute };