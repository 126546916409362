import React, {useEffect, useState} from "react";

import { Container, Row, Col} from "reactstrap";
import AdminTable from "../../Layout/tables/AdminTable";
import GeneralReport from "./GeneralReport";
import { OperatorTable } from "./OperatorTable"
import { GetAllUsers } from "../../api/GetUser";
import Permission from "./Permission";
import CreateUserComponent from "./CreateUser";
import { getAllMachinePermission } from "../../api/permission/MachinePermission";
import { useTranslation } from 'react-i18next';


const AdminPanel = () => {

    const [data, setData] = useState([]);
    // const [activeMachines, setActiveMachines] = useState([]);
    const [activeMachineCount, setActiveMachineCount] = useState(0);
    const [availableMachines, setAvailableMachines] = useState([]);
    // const [availableMachineCount, setAvailableMachineCount] = useState(0);
    const [machineCount, setMachineCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [userCount, setUserCount] = useState(0);
    const [permission, setPermission] = useState([]);
    const { t } = useTranslation(['adminPanel']); // Using the 'adminPanel' namespace



    const fetchUsers = async (next) => {
        const response = await GetAllUsers(next);
        const nextToNext = response.data.next;
        setUsers((prev) => [...prev, ...response.data.results]);
        // if we have next url page then get next page data and set it to user state
        if (nextToNext){
            await fetchUsers(nextToNext)
        }
    } 

    const fetchMachinePermission = async (next = null) => {
        /*
            * fetch all users who are allowed on the machine
        */
        try {
            const response = await getAllMachinePermission(next);
            if (response.request.status === 200) {
                const data = response.data.results;
                const nextUrl = response.data.next;

                if (next === null) {
                    setPermission(data); // Set initial permission state
                } else {
                    setPermission(prevPermission => [...prevPermission, ...data]); // Append data to permission state
                };
    
                if (nextUrl) {
                    fetchMachinePermission(nextUrl); // Call the function again with next URL
                }
            } else {
                console.error(response.error);
            }
        } catch (error) {
            console.error(error);
        }
    };
    

    const loadDatas = () => {
        // get all users

        GetAllUsers().then(async (res) => {
            try {

                const users = res.data.results;
                const userCount = res.data.count;
                const status = res.request.status;
                const next = res.data.next;

                // set data to state
                setUsers(users);
                setUserCount(userCount);

                if (next){
                    await fetchUsers(next);
                }
            }
            catch (e) {
                // console.log(e);
            };
        });
    };


    useEffect(() => {
        // get all users
        loadDatas();
        fetchMachinePermission();
    }, []);

    useEffect(() => {
        // get available machines
        const socket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}/machine/`);

        socket.onopen = () => {
            // connection opened
            socket.send(JSON.stringify({
                "action": "list",
                "request_id": new Date().getTime(),
            }));
        };

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                const machines = data.data;
                const machineInstance = machines.data;
                const activeMachineCount = machines.active_machine_count;
                const machineCount = machines.machine_count;
                
                // set data to state
                setData(machines);
                setActiveMachineCount(activeMachineCount);
                setAvailableMachines(machineInstance.reverse());
                setMachineCount(machineCount);

            }
            catch (e) {
                // console.log(e);
            };
        };

    }, [users]);



    return (
        <div className="page-content">

            <Container fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <GeneralReport 
                            machineCount={machineCount} 
                            activeMachineCount={activeMachineCount}
                            operatorCount={userCount}
                            t={t}
                        />
                    </Col>
                </Row>
                <Row>

                    <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                        <Permission 
                            availableMachines={availableMachines} 
                            operators={users} 
                            loadDatas={loadDatas}
                            permission={permission}   
                            fetchMachinePermission={fetchMachinePermission}
                            t={t}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                        <AdminTable 
                            availableMachines={availableMachines} 
                            operators={users} 
                            permission={permission}
                            t={t}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={2} lg={12} xl={5}>
                        <CreateUserComponent loadDatas={loadDatas} t={t}/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                        <OperatorTable
                            operators={users}
                            t={t}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
    }

export default AdminPanel;
