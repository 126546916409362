
import React, { useEffect, useMemo, useState } from 'react';
import TableContainer from '../../Components/Common/TableContainerReactTable';
import { DecryptUserInfo } from '../../api/helpers/decrypt';

const MachineDetailTable = (props) => {
    // const [error, setError ] = useState([]);
    const {toggle, removeToggle, machine } = props;

    const searchTable = machine && machine.map((item) => { 
        const data = {
            "id": item.machine.id,
            "name": item.machine.name,
            "status": item.status ? 'run' : 'stop',
            "order": item.order,
            "roll": item.roll,
            "created_at": item.created_at,
            "updated_at": item.updated_at,
            "proccess": [item.id, item.name, item.status, item.order, item.roll]
        }
        return data });

    let fiels =  
    [
        {
            Header: "ID",
            accessor: (cellProps) => {
            return (
                <span className="fw-semibold">{cellProps.id}</span>
            )
            },
            disableFilters: true,
            filterable: false,
        },
    
        {
            Header: props.t('machineName'),
            accessor: "name",
            disableFilters: true,
            filterable: false,
        },      
        {
            Header: props.t('partyNo'),
            accessor: (cellProps) => {
                const forward = cellProps.order && `/order/${cellProps.order.id}`
                return (
                    cellProps.order === null ? <span className="badge bg-soft-warning text-warning fs-7">{props.t('noOrder')}</span> :
                    <span className="badge bg-soft-primary text-primary fs-6" id='order-forward'>
                        <a href={forward}>
                            {cellProps.order.party_no}
                        </a>
                    </span>
                )
            },
            disableFilters: true,
            filterable: false,
        }, 
        {
            Header: props.t('rollNo'),
            accessor: (cellProps) => {
                const forward = cellProps.roll && `/order/${cellProps.roll.id}`
                return (
                    cellProps.roll === null ? <span className="badge bg-soft-secondary text-secondary fs-7">{props.t('noRoll')}</span> :
                    <span className="badge text-primary fs-6">
                            {cellProps.roll.roll_count_of_order}
                    </span>
                )
            },
            disableFilters: true,
            filterable: false,
        },  
        {
            Header: props.t('status'),
            accessor: (cellProps) => {
                return (
                    {
                    'run': <span className="badge bg-soft-success text-success fs-7">{props.t('run')}</span>,
                    'stop': <span className="badge bg-soft-danger text-danger fs-7">{props.t('stop')}</span>,
                    }[cellProps.status] || null
                )
            },
            disableFilters: true,
            filterable: false,
        },
    ];  

      
    const columns = useMemo(
        () => {
            if (DecryptUserInfo().status ==='superadmin'){
                fiels.push({
                    Header: "ISLEMLER",
                    accessor: (cellProps) => {
                        return (
                            <div className="icon-box"> 
                                <i className="bx bxs-edit text-success bg-soft-success fs-6 me-3 p-1" 
                                    style={{cursor: 'pointer', border: '1px solid', borderRadius: '5px'}}
                                    onClick={
                                        () => toggle(cellProps.id)
                                    }
                                ></i>
                                <i className="bx bx-trash text-dark bg-soft-dark fs-6 p-1" 
                                    style={{cursor: 'pointer', border: '1px solid', borderRadius: '5px'}}
                                    onClick={() => removeToggle(cellProps.id)}
                                ></i>
                            </div>
                       )
                    },
                    disableFilters: true,
                    filterable: false,
                })
                return fiels;
            }
            else{
                return fiels;
            }
        },[]
    );
      
    return (
        <React.Fragment >
            {
                <TableContainer
                    columns={(columns || [])}
                    data={(searchTable.reverse() || [])}
                    flaw={true}
                    isPagination={true}
                    isGlobalFilter={true}
                    iscustomPageSize={false}
                    isBordered={false}
                    customPageSize={5}
                    className="custom-header-css table align-middle table-nowrap"
                    tableClassName="table-centered align-middle table-nowrap mb-0"
                    theadClassName="text-muted table-light"
                    SearchPlaceholder='Search...'
                />
            }
            </React.Fragment >
        );
}; 
export default MachineDetailTable;