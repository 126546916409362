import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";

const token = DecryptToken();


const getMachineService = async (next=null) => {
    const url = next ? next : `${process.env.REACT_APP_API_URL}/machineservice/`;

    const api = new Promise((resolve, reject) => {
        try{
            axios({
                method: 'get',
                url: url,
                headers: {
                    Authorization: token
                }
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error)
            });
        }
        catch (error){
            // console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response;
      } catch (error) {
        return error;
      }
};

const getSingleMachineService = async (service_id) => {

    const api = new Promise((resolve, reject) => {
        try{
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/machineservice/${service_id}/`,
                headers: {
                    Authorization: token
                }
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error)
            });
        }
        catch (error){
            // console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response;
      } catch (error) {
        return error;
      }
};


const getAvaibleMachineService = async (next=null) => {
    // get machine which is not working
    const url = next ? next : `${process.env.REACT_APP_API_URL}/machineservice/?status=false`;

    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: url,
            headers: {
                Authorization: token
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error)
        });
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response;
    } catch (error) {
        return error;
    };
};

const getRollMachine = async (roll_id) => {
    // get machine which is working on spesific roll
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/machineservice/?roll=${roll_id}`,
            headers: {
                Authorization: token
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error)
        });
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response;
    } catch (error) {
        return error;
    };
};



const getMachines = async (next=null) => {
    // get all machines from backend
    const url = next ? next : `${process.env.REACT_APP_API_URL}/machine/`;

    const api = new Promise((resolve, reject) => {
        try{
            axios({
                method: 'get',
                url: url,
                headers: {
                    Authorization: token
                }
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error)
            });
        }
        catch (error){
            // console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response;
      } catch (error) {
        return error;
      }
}



async function EditMachineAPI (id, name){

    const api = new Promise((resolve, reject) => {
        // this function update spesific machine name
        try{
            axios({
                method: 'patch',
                url: `${process.env.REACT_APP_API_URL}/machine/${id}/`,
                headers: {
                    Authorization: token
                },
                data: {
                    "name": name
                }})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error){
            console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
      } catch (error) {
        return {
            error: error
        }; // Handle the error appropriately or return a default value.
      }
        
};

const removeMachineAPI = async (id) => {
    // delete a machine
    const api = new Promise((resolve, reject) => {
        // this function update spesific machine name
        try{
            axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_URL}/machine/${id}/`,
                headers: {
                    Authorization: token
                }})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error){
            console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
    } 
    catch (error) {
        return {
            error: error
        }; // Handle the error appropriately or return a default value.
      }
};

const createMachineAPI = async (name) => {
    const api = new Promise((resolve, reject) => {
        // this function update spesific machine name
        try{
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/machine/`,
                headers: {
                    Authorization: token
                },
                data: {
                    "name": name
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error){
            console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
      } catch (error) {
        return {
            error: error
        }; // Handle the error appropriately or return a default value.
      }
};

const startMachineAPI = async (machine_id, roll_id, order_id) => {
    const api = new Promise((resolve, reject) => {
        // this function start spesific machine
        try{
            axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_URL}/machineservice/${machine_id}/`,
                headers: {
                    Authorization: token
                },
                data: {
                    "roll": roll_id,
                    "order": order_id,
                    "status": true
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error){
            console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
    } catch (error) {
        return error
    }
};


const stopMachineAPI = async (machine_id) => {
    const api = new Promise((resolve, reject) => {
        // this function stop spesific machine
        try{
            axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_URL}/machineservice/${machine_id}/`,
                headers: {
                    Authorization: token
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error){
            console.error(error);
        };
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
    } catch (error) {
        return error
    }
};

const setUserMachineAPI = async (machine_id, user_id, date) => {
    const api = new Promise((resolve, reject) => {
        // this function stop spesific machine
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/machine/${machine_id}/`,
            headers: {
                Authorization: token
            },
            data: {
                "user": user_id,
                "permission_date": date
            }
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });
    
    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
    } catch (error) {
        return error
    }
};


const removeUserMachineAPI = async (machine_id) => {
    const api = new Promise((resolve, reject) => {
        // this function stop spesific machine
        axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_API_URL}/machineservice/${machine_id}/permission/`,
            headers: {
                Authorization: token
            }
        })
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api; // Wait for the Promise to be resolved.
        return response
    } catch (error) {
        return error
    }
};





export {
    getMachineService, getAvaibleMachineService, getMachines, 
    EditMachineAPI, removeMachineAPI, createMachineAPI, 
    startMachineAPI, stopMachineAPI, setUserMachineAPI, 
    removeUserMachineAPI, getRollMachine, getSingleMachineService
};