import React, { useState, startTransition } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
}
    from 'reactstrap';


const LanguageDropdown = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => { setIsOpen(!isOpen) };

    const { i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const changeLanguage = (lng) => {
        startTransition(() => {
            i18n.changeLanguage(lng);
        });
    };

    const getLanguageLabel = () => {
        if (i18n.language === 'en') {
            return 'English';
        } else if (i18n.language === 'tr') {
            return 'Türkçe';
        } else {
            return 'Language'; // Default fallback
        }
    };

    const languageColors = {
        en: 'danger',
        tr: 'secondary'
    };



    return (

        <React.Fragment>
            <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ms-sm-3">
                <DropdownToggle tag="button" type="button"
                    className={`btn btn-icon btn-topbar btn-ghost-secondary w-100 d-flex align-items-center justify-content-center rounded text-${languageColors[currentLanguage]}`}>
                    <span className="d-flex align-items-center">
                        <i className={`bx bx-world fs-22 me-1 text-${languageColors[currentLanguage]}`}></i>
                        <span className={`text-${languageColors[currentLanguage]}`} style={{ fontSize: '13px' }}>{getLanguageLabel()}</span>
                    </span>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end" end={true}>
                    <DropdownItem>
                        <Button size="sm" onClick={() => changeLanguage('en')} className="me-2 w-100" color={i18n.language === 'en' ? 'danger' : 'outline-danger'}>English</Button>
                    </DropdownItem>
                    <DropdownItem>
                        <Button size="sm" onClick={() => changeLanguage('tr')} className="w-100" color={i18n.language === 'tr' ? 'secondary' : 'outline-secondary'}>Türkçe</Button>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )


}

export { LanguageDropdown };