import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";

const token = DecryptToken();

const getDailyFlawTypeCount = async (day=7) => {
    //* get daily flaw type count 
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/flawtype/daily/${day}/`,
            headers: {
                'Authorization': token
            }
        }).then((res) => {
            resolve(res.data);
        }
        ).catch((error) => {
            reject(error);
        });
    });

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    }
}

const searchFlawType = async (FlawTypeName) => {
    //* search flaw type
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/search/flawtype/${FlawTypeName}/`,
            headers: {
                'Authorization': token
            }
        }).then((res) => {
            resolve(res.data);
        }
        ).catch((error) => {
            reject(error);
        });
    });

    try {
        const response = await api;
        return response;
    }
    catch (error) {
        return error;
    }
}

export { getDailyFlawTypeCount, searchFlawType};
