import { configureStore } from '@reduxjs/toolkit';
import machineServiceSlice from './slices/machineApi';
import orderSlice from './slices/orderApi';
import orderDetailSlice from './slices/orderDetailApi';
import userSlice from './slices/userApi';
import thunk from 'redux-thunk';

export default configureStore({
  reducer: {
    machineServiceApi: machineServiceSlice,
    orderApi: orderSlice,
    orderDetailApi: orderDetailSlice,
    userApi: userSlice,
  },
  middleware: [thunk]
});
