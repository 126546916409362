import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const PercentChart = (props) => {
  const {flaw} = props

  /* 
    !###### EXAMPLE FLAW ARRAY ############
    * [
    *  ['Delik', '2'] -> flaw name & flaw count
    * ]
  */  

  const colors = ["#f26a8d", "#CFBAF0", "#D08C60", "#e4c027", "#8241a3", "#fd9e2a", "#5e4f1a", "#cde6f2", "#3a7a89", "#71d35c", "#f0e8a1", "#429b4e", "#f58c19", "#637c8e", "#c31e3f", "#eb45b0", "#9f607b", "#24afef", "#f07fc5", "#3a7a89"];


  const options = { //data on the x-axis
    series: flaw.map((item) => { return item[1] }),
    options: {
      chart: {
        type: 'donut',
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 1500,
            animateGradually: {
                enabled: true,
                delay: 150
            }
        },
      events: {
            dataPointSelection: function(event, chartContext, config) {
                const selectedDataIndex = config.dataPointIndex;
                const selectedData = flaw[selectedDataIndex];  // flaw type code id
                sessionStorage.setItem('flaw_type_code', selectedData[2]);
                window.location.href = '/report';
            }
        },
      },
      labels: flaw.map((item) => { return item[0]}),
      colors: colors,
    },
  }

  return (
    <div>
      <ReactApexChart
        options={options.options}
        series={options.series}
        type="donut"
        width="100%"
        height={278}
      />
    </div>
  )
}

export default PercentChart;

