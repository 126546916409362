import React from 'react';
import ReactApexChart from "react-apexcharts";


const MyCharts = (props) => {
  const {flaw} = props;




  /* 
    !###### EXAMPLE FLAW ARRAY ############
    * [
    *  ['Delik', '2'] -> flaw name & flaw count
    * ]
  */  

  const series = [ //data on the y-axis
    {
      name: "Hata Sayısı",
      data: flaw.map((item) => {
        return item[1] // return flaw counts as new array
      })
    }
  ];

  // const [colors] = ['#BEE1E6', '#FDE2E4', '#CFBAF0', '#FBF8CC', '#797D62', '#D08C60','#F1DCA7', '#d3f8e2', '#d0b8ac', '#f27a7d', '#daf5fe', '#f6daca', '#aa4465', "#ac2b9e", "#71d35c", "#f0e8a1", "#429b4e", "#f58c19", "#637c8e", "#c31e3f", "#eb45b0", "#9f607b", "#24afef", "#f07fc5", "#3a7a89"  ]
  // const colors = ['#ffc107', '#9c27b0', '#673ab7', '#3f51b5', '#00bcd4', '#e81e63', '#009688', '#4caf50', "#f44336", '#e81e63' ];
  const colors = ["#f26a8d", "#CFBAF0", "#D08C60", "#e4c027", "#8241a3", "#fd9e2a", "#5e4f1a", "#cde6f2", "#3a7a89", "#71d35c", "#f0e8a1", "#429b4e", "#f58c19", "#637c8e", "#c31e3f", "#eb45b0", "#9f607b", "#24afef", "#f07fc5", "#3a7a89"];


  const options = { //data on the x-axis
    options: {
        chart: {
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 1500,
                animateGradually: {
                    enabled: true,
                    delay: 150
                }
            },
            events: {
                dataPointSelection: function(event, chartContext, config) {
                    const selectedDataIndex = config.dataPointIndex;
                    const selectedData = flaw[selectedDataIndex];
                    sessionStorage.setItem('flaw_type_code', selectedData[2]);  // flaw type code id
                    window.location.href = '/report';
                }
            },
          type: 'bar',
          height: '50%',

        },
        plotOptions: {
          bar: {
            columnWidth: '40%',
            distributed: true,
            borderRadius: 2,
              cursor: 'pointer',
          },
        },
        dataLabels: {
          offsetY: -20,
          enabled: true,
          style: {
            colors: ['darkblue'],
          }
        },
        colors: colors,
        legend: {
          show: true
        },
        
        xaxis: {
          categories: flaw.map((item) => { return item[0]}), //  return flaw name as new array
          labels: {
            style: {
              fontSize: '12px',
            }
          },
        }
    }
  };

  return (
    <div>
      <ReactApexChart
        options={options.options}
        series={series}
        type="bar"
        width="100%"
        height={260}
      />
    </div>
  )
}

export default MyCharts;

