import { useFormik } from "formik";
import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, Alert } from 'reactstrap';
import * as Yup from "yup";
import LoginAPI from '../api/Login.js';
import { GetUser } from '../api/GetUser';
import Cookies from "js-cookie";


const Login = () => {

    const [userLogin, setUserLogin] = useState([]);
    const [error, setError] = useState(null);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            username: userLogin.username || '',
            password: userLogin.password || '',        
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your Username"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: async (values)  => {
            const response = await LoginAPI(values.username, values.password);
            console.log(response, 'response')
            if (response.request.status === 200){
                // get user info
                const token = `Token ${response.data.key}`;
                GetUser(token).then((res) => {
                    const data = res.data;
                    const info = {
                        "status": data.status,
                        "username": data.username
                    }
                    Cookies.set('info', JSON.stringify(info), { expires: 7 });

                    // setTimeout(() => {
                        // if user status is not admin redirect to order page
                        if (data.status === "user"){
                            window.location.href = "/order";
                        }
                        else {
                            window.location.href = "/dashboard";
                        }
                    // }, 1000);
                });
            }
            else{
                setError("Invalid Username or Password");
            };
        },
    });

    const showPassword = (e) => {
        e.preventDefault();
        const password = document.getElementById("password");
        if (password.type === "password"){
            password.type = "input";
        }
        else{
            password.type = "password";
        };
    };

        document.title = "Fralz Login";
        return (
            <React.Fragment>                    
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <Link to="/" className="d-inline-block auth-logo">
                                                <img src='' alt="" height="20" />
                                            </Link>
                                        </div>
                                        <p className="mt-3 fs-15 fw-medium">Login</p>
                                    </div>
                                </Col>
                            </Row>
    
                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    {/* show error if is exist */}
                                    { error && <Alert color="danger" className="text-center">{error}</Alert>} 
                                    <Card className="mt-4">
                                        <CardBody className="p-4">
                                            <div className="text-center mt-2">
                                                <h5 className="text-primary">Welcome Back !</h5>
                                                <p className="text-muted">Sign in to continue to dashboard.</p>
                                            </div>
                                            <div className="p-2 mt-4">
                                                <Form
                                                    onSubmit={validation.handleSubmit}
                                                    action="#">
    
                                                    <div className="mb-3">
                                                        <Label htmlFor="username" className="form-label">Username</Label>
                                                        <Input
                                                            name="username"
                                                            className="form-control"
                                                            placeholder="Enter username"
                                                            style={{border: '1px solid #80808047', borderRadius: '3px', disabled: true}} 
                                                            type="input"
                                                            onChange={validation.handleChange}
                                                            value={validation.values.username}
                                                        />
                                                        {validation.errors.username ? validation.errors.username : null}
                                                    </div>
    
                                                    <div className="mb-3">
                                                        <div className="float-end">
                                                            <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                        </div>
                                                        <Label className="form-label" htmlFor="password-input">Password</Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <Input
                                                                name="password"
                                                                type="password"
                                                                id="password"
                                                                className="form-control pe-5"
                                                                placeholder="Enter Password"
                                                                style={{border: '1px solid #80808047', borderRadius: '3px', disabled: true}} 
                                                                onChange={validation.handleChange}
                                                                value={validation.values.password}
                                                            />
                                                            {validation.errors.password ? validation.errors.password : null}
                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" 
                                                                    type="button" id="password-addon" onClick={(e) => showPassword(e)}>
                                                                <i className="ri-eye-fill align-middle"></i>
                                                            </button>
                                                        </div>
                                                    </div>
    
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                        <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                    </div>
    
                                                    <div className="mt-4">
                                                        <Button color="success" className="btn btn-success w-100" type="submit">
                                                            Sign In
                                                        </Button>
                                                    </div>
    
                                                </Form>
                                            </div>
                                        </CardBody>
                                    </Card>
    
                                    <div className="mt-4 text-center">
                                        <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                                    </div>
    
                                </Col>
                            </Row>
                        </Container>
                    </div>
            </React.Fragment>
        );
};

export default Login;