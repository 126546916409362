import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; 
import 'dayjs/locale/tr';
import { useTranslation } from 'react-i18next';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default function DatePickerMaterialUI(props) {
  const { i18n } = useTranslation();

  // when language change update dayjs
  dayjs.locale(i18n.language);

  const dateFormat = i18n.language === 'tr' ? 'DD-MM-YYYY' : 'MM-DD-YYYY';


  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label={props.label} defaultValue={props.date} onChange={(date) => props.handleSelect(date, props.func)} format={dateFormat} day/>
      </LocalizationProvider>
    </div>
  );
}