import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useProfile } from "../Hook/UserHooks";
import { DecryptToken, DecryptUserInfo } from "../api/helpers/decrypt";


const AuthProtected = (props) => {
  const token = DecryptToken();

  /*
    Navigate is un-auth access protected routes via url
    */

  if (!token) {
    return (
      <Navigate to={{ pathname: "/auth/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };