import React, {useState, useEffect} from "react";
import 
{   Form, 
    FormText, 
    FormGroup, 
    Label, 
    Input,
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader, 
    Button, 
    Row, 
    Col, 
    UncontrolledDropdown, 
    DropdownItem, 
    DropdownMenu, 
    DropdownToggle 
    } 
from "reactstrap";
import { startMachineAPI, stopMachineAPI, getAvaibleMachineService, getRollMachine, getSingleMachineService } from "../../api/machine/Machine";
import { UpdateRoll, CreateRoll, ActivateRoll, DeactivateRoll } from "../../api/roll/Roll";
import { FinishParty, ActivateParty, getOrder } from "../../api/order/Order";
import { useParams } from "react-router-dom";
import { isEmptyArray } from "formik";

import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";

const token = DecryptToken();

const FlawList = (props) => {
    const { roll,
            machine,
            action,
            active_roll, 
            loaderToggle, 
            getActiveRoll, 
            forceRemoveLoader, 
            changeCurrentRoll,
            setOrderSession
        } = props;

    const {id} = useParams(); 
    const [selectedItem, setSelectedItem] = useState(false);
    const [readyMachine, setReadyMachine] = useState([]);
    const [machineId, setMachineId] = useState(false);  // id , name , roll
    const [orderMachine, setOrderMachine] = useState(false);
    const [orderStatus, setOrderStatus] = useState(false);
    const [orderRoll, setOrderRoll] = useState(null);
    const [lastRoll, setLastRoll] = useState(null);
    const [activeRoll, setActiveRoll] = useState(null);
    const [selectedRoll, setSelectedRoll] = useState(null);
    const [selectedRollCount, setSelectedRollCount] = useState(null);
    const [newRollCheck, setNewRollCheck] = useState(false);
    const [isPartyFinish, setIsPartyFinish] = useState(false);  // if user want to close party and we have active roll, we should show roll modal and close party after roll modal closed

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [success, setSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [info, setInfo] = useState(false);
    const [infoMsg, setInfoMsg] = useState("");

    const [modal, setModal] = useState(false);
    const [modalMsg, setModalMsg] = useState("");
    const [rollModal, setRollModal] = useState(false);
    const [activateModal, setActivateModal] = useState(false);
    const [partyModal, setPartyModal] = useState(false);

    const [isVerified, setIsVerified] = useState(null);


    const newRollControl = () => {setNewRollCheck(!newRollCheck)}

    const callRollMachine = async (roll_id) => {
        /*
        * this function will be return roll's machine data from api
        * if there is no machine in this roll then return empty list
        */
        if (roll_id !== null){
            const response = await getRollMachine(roll_id);
            try {
                const data = response.data.results[0];
                setMachineId([data.machine.id, data.machine.name, data.roll, data.id]);
                setOrderMachine(data);
                
            }
            catch (e) {
                setMachineId(false);
            };
            // after fix here! use json instead of array
        };
    };


    const callMachine = async (next=null) => {
        // get machine datas from api
        const response = await getAvaibleMachineService(next);
        const data = response.data;
        // set machines
        setReadyMachine((prev) => [...prev, ...data.results])
        if (data.next !== null) {
            callMachine(data.next);
        }              
    };

    useEffect(() => {
        const data = machine.data;
        if (data && !undefined && data.length > 0) {
            const isVerified = data[0].is_verified;
            setInfo(!isVerified);
            setInfoMsg(props.t('machineControlMsg'))
            if (isVerified && action && action === 'update') {
                setSuccessMsg(props.t('machineSuccessMsg'));
                setSuccess(true);
                setTimeout(() => {
                    setSuccessMsg("");
                    setSuccess(false);

                }, 5000)
            }
        }
    }, [machine])

    const rollToggle = () => {
        // when user click roll button, open roll modal and clear the old feedbacks
        setError(false);
        setSuccess(false);
        setRollModal(!rollModal);
        
    };

    const activateToggle = (e) => {
        // when user click activate button, open activate modal and clear the old feedbacks
        setError(false);
        setSuccess(false);
        setActivateModal(!activateModal);
    };

    const partyToggle = (e) => {
        setError(false);
        setSuccess(false);
        setPartyModal(!partyModal);
    };

    const toggle = (msg, decision) => {
        if (msg !== null && decision === null){
            try {
                setModalMsg(msg);
                setModal(!modal)
            }
            catch (e) {
                // console.log(e);
            };
        }
        else if (msg === null && decision){
            try {
                runMachine();
                setModal(!modal);
            }
            catch (e) {
                // console.log(e);
            };
        }
        else if (msg === null && decision === false){
            try {
                stopMachine();
                setModal(!modal);
            }
            catch (e) {
                // console.log(e);
            };
        }
        else {
            setModal(!modal);
        }
    };

    useEffect(() => {
        /* 
        * call machine function when component mounted
        */
        callMachine();
    }, [])

    const changeSelectedRoll = (roll, roll_count) => {
        /*
        * when user change the roll then change the selected roll
        */
        setSelectedRoll(roll);  // set roll item
        // setActiveRoll(roll); // set active roll
        setSelectedRollCount(roll_count); // set order's roll count
        changeCurrentRoll(roll); // change current roll in order detail index.js
    };


    useEffect(() => {
        /*
        * when active roll changes set the active roll state
        * if there is no active roll in order then, 
        * set the selected roll as first roll in order
        */
        if (active_roll && active_roll != activeRoll){ 
            changeSelectedRoll(active_roll, active_roll.roll_count_of_order); 
            setActiveRoll(active_roll); // set active roll
            callRollMachine(active_roll.id);  // call roll machine function 
        }
    }, [active_roll])

    useEffect(() => {
        /* 
        * when total roll changes set the roll state 
        * control the roll object first, if it is not null then set the roll state
        * order's roll can not be 0, default is 1 in backend 
        */
        if (roll != null && roll.length > 0) {
            setOrderRoll(roll);
        };
    }, [roll])

    useEffect(() => {
        /*
        * when roll set and active roll set,
        * but there is no active roll in order then,
        * set the selected roll as last created roll in order
        */
       if (orderRoll && orderRoll.length > 0 && activeRoll === null){
            // filter the last roll in order and set the selected roll
            const lastRoll = orderRoll.filter((item) => item.roll_count_of_order === orderRoll.length)[0];
            setLastRoll(lastRoll);
            changeSelectedRoll(lastRoll, lastRoll.roll_count_of_order);
            callRollMachine(lastRoll.id);  // call roll machine function
        };
    }, [orderRoll])

    const getOrderData = async () => {
        // get current order datas from api
        const response = await getOrder(id);
        if (response.request.status === 200){
            setOrderStatus(response.data.status);
        }
        else {
            window.location.href = "/page-not-found";
        }
        return response;
    }

    const fetchSessionMachine = async () =>{
        /*
            * when roll deleted, we set last machine id to session storage
            * before page load, get machine id from sesssion and fetch machine
            * from backend and, set this machine as selected item to state
        */
        // check session storage to current order has any machine
        let instance;
        try {
            instance = JSON.parse(sessionStorage.getItem(`order_${id}`));

            if (instance){
               const machine = await getSingleMachineService(instance.id);
               handleSelect(machine.data)
            };
        }
        catch (e) {
            // console.log(e)
        }
    };

    useEffect(() => {
        // get order status 
        getOrderData(); 
        fetchSessionMachine();
    }, [])

    // START MACHINE FUNCTIONS
    const handleSelect = (item) => {
        // change selected machine
        setSelectedItem(item);
        sessionStorage.setItem(`order_${id}`, JSON.stringify(item));
    };

    const runMachine = async () => {
        /*
        * First active selected roll then
        * Run machine on this roll and order
        * get selected machine data from selectedItem
        * get selected roll data from selectedRoll
        * get selected order data from id
        * @params:
        *   selectedItem.id: rolls/machine service id
        *   selectedRoll.id: roll id
        *   id: order id
        */
        if (selectedItem && selectedRoll.id) {
            if (orderStatus){ 
                const rollResponse = await activateRoll(); // activate roll
                if (rollResponse) {
                    const response = await startMachineAPI(selectedItem.id, selectedRoll.id, id);
                    const status = response.request.status;
                    if (status === 403){
                        setErrorMsg(props.t('machineFailMsg'));
                        setError(true);
                    }
                    else if (status === 200){
                        setMachineId([selectedItem.id, selectedItem.machine.name, selectedRoll.id, response.data.id]);
                        setOrderMachine(response.data);
                        
                        // check ai machine is active or not ?
                        setInfo(!response.data.is_verified);
                        setInfoMsg(props.t('machineControlMsg'));
                        if (response.data.is_verified) {
                            setSuccessMsg(props.t('machineSuccessMsg'));
                            setSuccess(true);
                            setTimeout(() => {
                                setSuccessMsg("");
                                setSuccess(false);
                            }, 5000)
                        }
                        setSelectedItem(false);
                        sessionStorage.removeItem(`order_${id}`);
                    }
                    else if (status === 400){
                        setErrorMsg(response.response.data.detail);
                        setError(true);
                    }
                    else {
                        setErrorMsg(props.t('serverError'));
                        setError(true);
                    };
                };
                
            }
            else {
                setErrorMsg(props.t('partyActiveMsg'));
                setError(true);
            }
        }
        else {
            setErrorMsg(props.t('machineChooseMsg'));
            setError(true);
        };
    };

    const stopMachine = async () => {
        if (machineId) {
            const response = await stopMachineAPI(machineId[3]);
            const status = response.request.status;
            if (status === 403){
                setErrorMsg(props.t('machineFailMsg'));
                setError(true);
            }
            else if (status === 200){
                setMachineId(false);
                setSuccessMsg(props.t('machineStopMsg'));
                setSuccess(true);
                setTimeout(() => {
                    setSuccessMsg("");
                    setSuccess(false);
                }, 5000)
                setSelectedItem(orderMachine);
                setOrderMachine(false);
                setReadyMachine([]); // clear machines
                callMachine(); // reload machines
                sessionStorage.removeItem(`order_${id}`);
            }
            else {
                setErrorMsg(props.t('machineFailMsg'));
                setError(true);
            };
        }
        else if (orderMachine) {
            const response = await stopMachineAPI(orderMachine.id);
            const status = response.request.status;
            if (status === 200){
                setMachineId(false);
                setSuccessMsg(props.t('machineStopMsg'));
                setSuccess(true);
                setTimeout(() => {
                    setSuccessMsg("");
                    setSuccess(false);
                }, 5000)
                setSelectedItem(orderMachine);
                setOrderMachine(false);
                setReadyMachine([]); // clear machines
                callMachine(); // reload machines
            };
        };
    };
    // END MACHINE FUNCTIONS


    // START ROLL FUNCTIONS
    const justActivate = async (roll) => {
        /* 
        * This function just activate roll
        * activateRoll function control the active roll
        * and if it has active roll then deactivate it
        */
        const response = await ActivateRoll(roll.id);
        const status = response.request.status;
        if (status === 200){
            // setSuccessMsg("Top başarıyla aktifleştirildi!");
            // setSuccess(true);
            getActiveRoll(); // get active roll
            // setActiveRoll(response.data); // set active roll
            return true;
        }
        else if (status === 403){
            // setErrorMsg("Bu işlemi gerçekleştirmek için yetkiniz bulunmamaktadır!");
            // setError(true);
            return false;
        }
        else {
            // setErrorMsg("Bir şeyler ters gitti! Lütfen teknik destek ile iletişime geçiniz!");
            // setError(true);
            return false;
        }

    }
 
    const activateRoll = async (event) => {
        /* 
        * Activate roll and deactivate active roll
        * First check the active roll
        * if there is active roll then deactivate it
        * if there is no active roll then directly activate roll
        * @params:
        *   activeRoll.id: active roll id
        *   selectedRoll.id: selected roll id
        */
        if (activeRoll !== null){
            const deactivateResponse = await DeactivateRoll(activeRoll.id);
            const deactivateStatus = deactivateResponse.request.status;
            if (deactivateStatus === 200){
                // activate roll
                return await justActivate(selectedRoll);
            }
            else if (deactivateStatus === 403){
                // setErrorMsg("Bu işlemi gerçekleştirmek için yetkiniz bulunmamaktadır!");
                // setError(true);
                return false;
            }
            else {
                // setErrorMsg("Bir şeyler ters gitti! Lütfen teknik destek ile iletişime geçiniz!");
                // setError(true);
                return false;
            };
        }
        else {
            // if there is no active roll directly activate roll
            return await justActivate(selectedRoll);
        };
    };

    const createRoll = async () => {
        /*
        * This function create new roll
        * @params:
        *   - id: order id
        */
        const response = await CreateRoll(id);
        const status = await response.request.status;
        if (status === 201){
            setSuccessMsg("Eski top bitti! Yeni top oluşturuldu!");
            setSuccess(true);
            setTimeout(() => {
                setSuccessMsg("");
                setSuccess(false);
            }, 5000)
            getActiveRoll(); // get active roll
        }
        else if (status === 403){
            setErrorMsg(props.t('noPermission'));
            setError(true);
        }
        else {
            setErrorMsg(props.t('serverError'));
            setError(true);
        }
    };  

    const updateRoll = async (meter) => {
        /*
        * This function 
        * @params:
        *   - data: data of form  (length, kg)
        * @return:
        *   - status: response status
        */
        const response = await UpdateRoll(activeRoll.id, meter);
        const status = response.request.status;
        return status;
    }

    const changeUpdateRoll = async (event) => {
        /*
        * This function can handle roll update and create new roll
        * if user select new roll then create new roll
        * if user select not new roll then update roll
        * @params:
        *  - event: form event
        */
        event.preventDefault();
        const meter = event.target[0].value;
        const newRoll = event.target[1].checked;
        const updateStatus = await updateRoll(meter); // update roll status as false
        if (updateStatus === 200) { 
            stopMachine();  // stop machine
            setActiveRoll(null);  // set active roll null
            getActiveRoll(); // get active roll
        };
        if (newRoll){ 
            createRoll(); 
        }
        else { 
            setSuccessMsg("Top bitti!"); 
            setSuccess(true); 
            setTimeout(() => {
                setSuccessMsg("");
                setSuccess(false);
            }, 5000)
        };
        rollToggle();  // close roll modal
        if (isPartyFinish) {
            setIsPartyFinish(false);
            StopParty();
        }
    };

    const handleRollSelect = (roll, roll_count) => {
        /* 
        * when user select the roll then change the selected roll
        * then fetch the roll datas from api
        */
        changeSelectedRoll(roll, roll_count);
        callRollMachine(roll.id); // call roll machine function
    };
    // END ROLL FUNCTIONS

    // PART FUNCTIONS
    const handlePartySubmit = (event) => {
        /*
        * This function handle party submit
        * @params:
        *  - event: form event
        */
        event.preventDefault();
        if (activeRoll) {
            setIsPartyFinish(true);
            rollToggle();
        }
        else {
            StopParty();
        };
    };
 
    const StopParty = async () => {
        /*
        * Stop already active party/order
        */
        const response = await FinishParty(id);
        const status = response.request.status;
        if (status === 200){
            setSuccessMsg(props.t('partyFinishSuccessMsg'));
            setSuccess(true);
            setTimeout(() => {
                setSuccessMsg("");
                setSuccess(false);
            }, 5000)
            stopMachine(); // stop machine
            getOrderData(); // update order status
        }
        else if (status === 403){
            setErrorMsg(props.t('noPermission'));
            setError(true);
        }
        else {
            setErrorMsg(props.t('serverError'));
            setError(true);
        };
        setOrderSession(response.data.status); // set order status with new one to sessionStorage
    };

    const StartParty = async (event) => {
        /*
        * reactivate finished party/order
        */
        event.preventDefault();
        const response = await ActivateParty(id);
        const status = response.request.status;
        if (status === 200){
            setSuccessMsg(props.t('partySuccessStartMsg'))
            setSuccess(true);
            setTimeout(() => {
                setSuccessMsg("");
                setSuccess(false);
            }, 5000)
            getOrderData(); // update order status
        }
        else if (status === 403){
            setErrorMsg(props.t('noPermission'));
            setError(true);
        }
        else {
            setErrorMsg(props.t('serverError'));
            setError(true);
        };
        setOrderSession(response.data.status); // set order status with new one to sessionStorage
    };

    // const handleExcel = () => {
    //     loaderToggle();
    //     window.location.href=`${process.env.REACT_APP_API_URL}/excel/${id}/`;
    //     // catch the file ready event
    //     window.addEventListener('blur', () => { 
    //         forceRemoveLoader();
    //     });
    // };

    const handleExcel = async (e) => {
        try {
            loaderToggle();

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/excel/${id}/`, {
                responseType: 'blob',
                headers: {
                    'Authorization': token,
                },
            });
            // Create a Blob from the response data
            const blob = new Blob([response.data], {
                type: response.headers['content-type'], // Get the content type from response headers
            });

            // Create a link element
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            // Set the download attribute with a filename
            link.setAttribute('download', 'order_' + id + '_report.xlsx');

            // Append link to the body
            document.body.appendChild(link);

            // Trigger click event to start download
            link.click();

            // Remove the link element
            document.body.removeChild(link);

            // Release the blob URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        } finally {
            forceRemoveLoader(); // Remove the loader when the download is finished or an error occurs
        }
    }

   

    return (
        <div>
            {/* ALERTS */}
            {error && (
                <div className="alert alert-danger alert-dismissible fade show text-center" role="alert">
                    <strong >{errorMsg && errorMsg}</strong> 
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(false)}></button>
                </div>
            )}

            {success && (
                <div className="alert alert-success alert-dismissible fade show text-center" role="alert">
                    <strong >{successMsg && successMsg}</strong> 
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccess(false)}></button>
                </div>
            )}

            {info && (
                <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                    <strong>{infoMsg && infoMsg}</strong> 
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setInfo(false)}></button>
                </div>
            )}

            {/* MODALS */}
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader toggle={toggle}>{props.t('areYouSure')}</ModalHeader>
                <ModalBody>
                    {modalMsg && modalMsg}
                </ModalBody>
                <ModalFooter>
                {/* check the machine is already active or not  */}
                {machineId === false || machineId[2].roll_count_of_order !== selectedRoll.roll_count_of_order ? (
                    <Button color="success" onClick={() => toggle(null, true)}>
                        {props.t('start')}
                    </Button>
                ) : (
                    <Button color="danger" onClick={() => toggle(null, false)}>
                        {props.t('stop')}
                    </Button>
                )}
                </ModalFooter>
            </Modal>

            <Modal isOpen={rollModal} toggle={rollToggle} centered={true}>
                <ModalHeader toggle={rollToggle}>{props.t('rollChange')}</ModalHeader>
                
                <ModalBody>
                    <FormText color="danger">
                        <span >
                            {props.t('rollChangeMsg')}<br></br>
                            {props.t('rollChangeExtraMsg')}
                        </span>
                    </FormText>
                    <Form className="mt-3" onSubmit={(e) => changeUpdateRoll(e)}>
                        <FormGroup>
                            <Label for="rollMeter">{props.t('finishRollMeter')}</Label>
                            <Input 
                                required
                                type="number"
                                step="0.01" 
                                name="rollMeter" 
                                id="rollMeter" 
                                min={0}
                                placeholder={props.t('finishRollMeter')}
                                style={{border: '1px solid #80808047', borderRadius: '3px'}} 
                                />
                        </FormGroup>
                        
                        <FormGroup>
                            <Label for="newroll" style={{width: '100%'}}>{props.t('newRollQuestion')}</Label>
                            <Input 
                                type="checkbox"
                                name="newroll"
                                id="newroll"
                                placeholder="New Roll"
                                style={{border: '1px solid #80808047', borderRadius: '3px'}}
                                onChange={(e) => newRollControl(e)}
                            />
                            <span className="ms-3 text-muted">
                            {
                                newRollCheck && newRollCheck ? props.t('yes') : props.t('no')
                            }
                            </span>
                        </FormGroup>
                        {/* onClick={() => rollToggle(null, true)} */}
                        <Button color="success" type='submit' >{props.t('rollButton')}</Button>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={activateModal} toggle={activateToggle} centered={true}>
                <ModalHeader toggle={activateToggle}>{props.t('rollActivateButton')}</ModalHeader>
                
                <ModalBody>
                    <FormText color="danger">
                        <span >
                            {props.t('rollActivateWarning')}<br></br>
                            <span className="fw-bold">
                                { activeRoll !== null ?
                                    `${props.t('activeRollMsg')} : ${activeRoll.roll_count_of_order} . ${props.t('roll')}` :
                                    `${props.t('noActiveRollMsg')}`
                                }
                            </span><br></br>
                        </span>
                    </FormText>
                    <Form className="mt-3" onSubmit={activateRoll}>
                        <Button color="warning" type='submit' onClick={activateToggle}>{props.t('approve')}</Button>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={partyModal} toggle={partyToggle} centered={true}>
                <ModalHeader toggle={partyToggle}>{props.t('party')} {orderStatus ? props.t('finish') : props.t('start')}</ModalHeader>
                
                <ModalBody>
                    <FormText color="muted">
                        <span className={orderStatus === null ? "fw-bold text-danger" : "fw-bold" } >
                            { orderStatus ? props.t('partyStartMsg')
                                : ( orderStatus === null ? props.t('partyDeleteLimited')
                                : props.t('partyStartQuestion'))
                            }
                        </span>
                    </FormText>
                    <Form className="mt-3" onSubmit={orderStatus ? handlePartySubmit : StartParty}>
                        <Button color="warning" type='submit' onClick={partyToggle}>{props.t('approve')}</Button>
                    </Form>
                </ModalBody>
            </Modal>

            <Row>
                <Col xs={12} sm={12} md={12} lg={4}>
                    {/* dropdown  */}
                    <UncontrolledDropdown style={{float: 'left'}}>
                        <DropdownToggle caret color={machineId === false  & selectedItem === false  & orderMachine === false ? "dark" : 
                                                    (selectedRoll && machineId && machineId[2].roll_count_of_order === selectedRoll.roll_count_of_order ? "success": 
                                                    selectedItem ? "info"  : "danger")}>
                            {/* makina ismini ya da durumu belirleyen kontrol  */}
                            { machineId === false  & selectedItem === false & orderMachine === false ? props.t('chooseMachine') :
                                (selectedItem ? selectedItem.machine.name : 
                                machineId && machineId[2].roll_count_of_order === selectedRoll.roll_count_of_order ? machineId[1] : props.t('cantChooseMachine') )}
                        </DropdownToggle>
                        <DropdownMenu dark className="mt-1" style={{maxHeight: '150px', overflow: 'auto'}}>
                            {readyMachine && readyMachine.map((item) => (
                                <DropdownItem 
                                    key={item.id} 
                                    onClick={() => handleSelect(item)}
                                    className="d-flex justify-content-between align-items-center">
                                        {item.machine.name}
                                        {
                                            selectedItem && selectedItem.id === item.machine.id ?
                                            <i class='bx bxs-star text-warning' ></i> :
                                            ''
                                        }
                                </DropdownItem>
                            ))}
                            
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown style={{float: 'left'}} className="ms-2">
                        <DropdownToggle caret color="dark">
                            {selectedRollCount && selectedRollCount ? <span>{selectedRollCount}.{props.t('roll')}</span>
                                :  <span>{props.t('rollChange')}</span> }
                        </DropdownToggle>
                        <DropdownMenu className="mt-1" dark style={{maxHeight: '150px', overflow: 'auto'}}>
                            
                            {selectedRoll && orderRoll && orderRoll.map((item) => (
                                // selectedRoll && selectedRoll.id !== item.id ?
                                    <DropdownItem 
                                        key={item.id} 
                                        onClick={() => handleRollSelect(item, item.roll_count_of_order)}
                                        className="d-flex justify-content-between align-items-center">
                                        <span>{item.roll_count_of_order}.{props.t('roll')}</span>
                                        {
                                            selectedRoll.id === item.id ?
                                            <i className='bx bxs-star text-warning' ></i> :
                                            ''
                                        }
                                    </DropdownItem>
                                    // : null

                            ))}                            
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                    {/* control buttons */}
                    <div className="controlBox d-flex gap-2" style={{float:'right'}}>
                        {/* machine button */}
                        <Button color={machineId === false ? 'soft-success' : machineId[2].roll_count_of_order === selectedRoll.roll_count_of_order ? 'danger' : 'soft-success'} 
                                className=" btn-md" 
                                onClick={machineId === false ||   machineId && machineId[2].roll_count_of_order !== selectedRoll.roll_count_of_order  ? 
                                        () => toggle(props.t('machineStartQuestion'), null) :
                                        () => toggle(props.t('machineStopQuestion'), null)}>
                                    {machineId === false ? props.t('start') : machineId && machineId[2].roll_count_of_order === selectedRoll.roll_count_of_order ?  props.t('stop') : props.t('start')}
                        </Button>
                        {/* roll button */}
                        { selectedRoll && activeRoll && (activeRoll.id == selectedRoll.id) ? 
                            <Button color="soft-warning" 
                                    className=" btn-md" 
                                    onClick={rollToggle}>
                                    {props.t('rollButton')}
                            </Button>
                            :
                            ""
                        }
                        
                        {/* party button */}
                        <Button 
                            color={orderStatus && orderStatus ? "soft-danger" : "soft-info"}
                            className=" btn-md" 
                            onClick={orderStatus && orderStatus ? () => partyToggle(true): () => partyToggle(false)}  
                            >{orderStatus && orderStatus ? props.t('partyFinishButton'): (orderStatus === null ? props.t('partyWaitingButton') : props.t('startParty')) }
                        </Button>
                        {/* excel report button */}
                        <Button color="soft-primary" className=" btn-md" onClick={handleExcel}>Excel</Button>
                    </div>
                </Col>
            </Row>

        </div>

    );
};

export {FlawList};
