import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import OrderInfo from "./OrderInfo"
import OrderGeneral from "./OrderGeneral"
import { Row, Col, Container, Card, CardBody, Button,
        Modal, ModalBody, ModalHeader, ModalFooter,
         CardHeader, UncontrolledAlert, 
         Form, FormGroup, Label, Input, FormFeedback } 
from "reactstrap";
import FlawType from "./FlawType";
import { FlawList } from "./FlawList";
import FlawTable from  "../../Layout/tables/FlawTable"
import FlawMap from "./FlawMap";
import { FlawTypeDropdown } from "../../api/flaw/Flaw";
import { getOrder, getActiveRollOfOrder } from "../../api/order/Order";
import { DeleteRoll, DeactivateRoll, UpdateRoll, getRoll } from "../../api/roll/Roll";
import { Triangle } from "react-loader-spinner";
import { stopMachineAPI } from "../../api/machine/Machine";
import { isEmptyArray } from "formik";
import { useTranslation } from 'react-i18next';


const OrderDetail = () => {
    const {id} = useParams(); // get order id from url
    const [data, setData] = useState([]); // order data for information section
    const [orderStatus, setOrderStatus] = useState(false);
    const [roll, setRoll] = useState([]); // all roll for current order
    const [rollCount, setRollCount] = useState(1); // roll count for current order
    const [activeRoll, setActiveRoll] = useState(null); // active roll for current order
    const [currentRoll, setCurrentRoll] = useState(null); // current roll for current order
    const [flaw, setFlaw] = useState([]); // all flaw
    const [activeMachine, setActiveMachine] = useState([]); // active machine for current order
    const [action, setAction] = useState(null);
    const [webSocket, setWs] = useState(null);
    const [isNaturalClose, setIsNaturalClose] = useState(false);

    // for flaw type
    const [flawType, setFlawType] = useState([]);
    const [flawWColor, setFlawWColor] = useState([]);
    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(1);
    const { t } = useTranslation(['orderDetail']); // Using the 'dashboard' namespace

    /* alert
        * format: json  
        * params: 
        *   message -> alert content
        *   type -> alert type ( success, warning, danger, etc.)
    */
    const [alert, SetAlert] = useState(false); 
    const [toggle, setToggle] = useState(false);
    const [reminder, setReminder] = useState(null);
    const [reminderToggle, setReminderToggle] = useState(false);
    const [editRollModal, setEditRollModal] = useState(false);
    const [rollId, setRollId] = useState(false);
    const [rollMeter, setRollMeter] = useState(null);
    const [showRollFeedBack, setRollFeedBack] = useState(false);

    const modalToggle = () => { setToggle(!toggle)};
    const handleReminderToggle = () => { setReminderToggle(!reminderToggle)}
    const loaderToogle = () => { setLoader(!loader) };
    const forceRemoveLoader = () => { setLoader(false) };

    const changeCurrentRoll = (roll) => {
        setIsNaturalClose(true);  // set natural close as true for websocket close
        loaderToogle();
        setCurrentRoll(roll);
    };

    const setOrderSession = (status) => {
        // set order status to sessionStorage
        sessionStorage.setItem(`order_${id}_status`, status);
    };

    const getOrderSession = () => {
        // get order status from sessionStorage
        const condition = sessionStorage.getItem(`order_${id}_status`);
        return JSON.parse(condition);
    }

    const orderControl = async () => {
        const response = await getOrder(id);
        const status = response.request.status;
        if (status === 200){
            setData(response.data);
            setOrderStatus(response.data.status);
            setOrderSession(response.data.status);
            // getActiveRoll();
        }
        else {
            window.location.href = "/page-not-found";
        }
    };

    const getActiveRoll = async () => {
        // get active roll for current order
        const response = await getActiveRollOfOrder(id);
        setActiveRoll(response.data.results[0]);
    };


    const getOrderRoll = () => {
        // get order's roll datas from websocket
        const socket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}/order/${id}/roll/`);

        socket.onopen = () => {
            socket.send(JSON.stringify({
                action: 'list',
                request_id: new Date().getTime()
            }));
        };

        // listen to onmessage event and if there is a message update the state
        socket.onmessage = (e) => {
            // filter roll data according to status and set status true rolls count as active roll count
            const result = JSON.parse(e.data);
            setRoll(result.data);
            setRollCount(result.data.length)
        };

        socket.onerror = (e) => {
            // console.log(e);
        };
    };

    const getFlawFilterFromSession = () => {
        // get flaw filter from session storage
        const filter = sessionStorage.getItem(`order_${id}_flaw_filter`);
        return JSON.parse(filter);
    };

    const setFlawFilterToSession = (flawTypeCode) => {
        // set flaw filter to session storage
        sessionStorage.setItem(`order_${id}_flaw_filter`, flawTypeCode);
    };

    const callWebsocket = (roll_id) => {
        // get current order's flaws from websocket
        // const socket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}/order/${id}/flaw/?roll=${roll_id}&page=${page}&size=5`);
        const socket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}/order/${id}/flaw/?roll=${roll_id}`);

        // set web socket instance with roll_id to state 
        setWs({
            socket: socket,
            roll_id: roll_id
        }); 

        socket.onopen = () => {
            socket.send(JSON.stringify({
                action: 'list',
                request_id: new Date().getTime()
            }));
        };

        // listen to onmessage event and if there is a message update the state
        socket.onmessage = (e) => {
            const socket_data = JSON.parse(e.data);
            const results = socket_data.data;
            const filterFlaw = getFlawFilterFromSession();
            if (socket_data.action === 'list'){
                setFlaw(results);
                setTimeout(() => {
                    setLoader(false);
                }, 100);
            }
            else if (socket_data.action === 'create'){
                // if flaw created update the state
                const newFlaw = socket_data.data;
                if (getOrderSession()) {
                    // if current roll id is equal to active roll update all state
                    // first check state, if there is no flaw in state, add new flaw to state
                    // create new flaw array and add new flaw to this array
                    // get flaw state prev data and add new flaw to this array first index
                    // if we already have data in state, check the new flaw id
                    if (currentRoll && currentRoll.id === newFlaw.results.roll) {
                        // if there is an active filter, check new flaw type code and if its,
                        // equal to filter, add this flaw to state
                        const filterCode = getFlawFilterFromSession();
                        // we got 0 flaw type code, so we can not control directly `filterCode`. Use false for control
                        if (filterCode && filterCode !== false) {
                            if (filterCode == newFlaw.results.flaw_type_code) {
                                // if filter code is equal to new flaw type code
                                // add this flaw to state
                                setFlaw((prevFlaw) => {
                                    if (prevFlaw.results.length === 0) {
                                        return {
                                            count: newFlaw.count,
                                            results: [newFlaw.results]
                                        };
                                    }
                                    else {
                                        return {
                                            count: newFlaw.count,
                                            results: [newFlaw.results, ...prevFlaw.results]
                                        }
                                    };
                                });
                            }
                            else {
                                // if filter code is not equal to new flaw type code
                                // just update the order's count
                                setFlaw((prevFlaw) => {
                                    return {
                                        count: newFlaw.count,
                                        results: prevFlaw.results
                                    }
                                });
                            }
                        }
                        else {
                            setFlaw((prevFlaw) => {
                                if (prevFlaw.results.length === 0) {
                                    return {
                                        count: newFlaw.count,
                                        results: [newFlaw.results]
                                    }
                                } else if (prevFlaw.results[0].id !== newFlaw.results.id) {
                                    return {
                                        count: newFlaw.count,
                                        results: [newFlaw.results, ...prevFlaw.results]
                                    }
                                } else {
                                    return {
                                        count: prevFlaw.count,
                                        results: prevFlaw.results
                                    }
                                };
                            });   
                        };
                    }
                    else {
                        setFlaw((prevFlaw) => {
                            return {
                                    count: newFlaw.count,
                                    results: prevFlaw.results
                            }
                        })
                    }
                }
                else {

                    // set reminder state
                    handleReminderToggle();
                    setReminder({
                        type: 'warning',
                        message: `${activeRoll ? activeRoll.roll_count_of_order + '.' : 'Aktif'} top'da yeni bir hata yakalandı.`
                    });

                    // set timeout for reminder
                    setTimeout(() => {
                        handleReminderToggle();
                        setReminder(null);
                    }, 3000);

                }
            }
            else if (socket_data.action === "delete") {
                // delete action
                const newFlaw =  socket_data.data;
                if (filterFlaw) {
                    if (filterFlaw == newFlaw.results.flaw_type_code) {
                        setFlaw((prevFlaw) => {
                            return {
                                count: newFlaw.count,
                                results: prevFlaw.results.filter(item => item.id !== newFlaw.results.id)
                            }
                        });
                    }
                }
                else {
                    setFlaw((prevFlaw) => {
                        return {
                            count: newFlaw.count,
                            results: prevFlaw.results.some(item => item.id === newFlaw.results.id) ?
                            prevFlaw.results.filter(item => item.id !== newFlaw.results.id) :
                            [newFlaw.results, ...prevFlaw.results]
                        }
                    });
                };
            };
        };

        socket.onclose = (event) => {
            if (!isNaturalClose) {
                // if socket is closed by server, try to reconnect
                console.log('WebSocket connection closed. Attempting to reconnect...');
                setTimeout(callWebsocket(currentRoll.id), 5000); // Reconnect after 5 seconds
            };
        };

        socket.onerror = (e) => {
             console.log(e);
         }
    };

    const callOrderMachine = () => {
        const socketMachine = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}/order/${id}/machine/`);
        // get web socket connection to server
        // listen to onopen event
        socketMachine.onopen = () => {
            socketMachine.send(JSON.stringify({
                action: 'list', //'list',
                request_id: new Date().getTime(),
            }));
        };

        // listen to onmessage event
        socketMachine.onmessage = (e) => {
            // update the state
            const res = JSON.parse(e.data);  // get websocket data from backend
            const action = res.action;  // get websocket action
            const results = res.data;  // get websocket machine data
            setActiveMachine(results);  // set active machine for selected order
            setAction(action);  // set action to control notifications
        };

        socketMachine.onerror = (e) => {
            // console.log(e);
        }
    };

    const fetchFlawType = async () => {
        const response = await FlawTypeDropdown();
        setFlawType(response.result);
    };

    const setup = () => {
        orderControl();
        getActiveRoll();
        callOrderMachine();
        fetchFlawType();
        getOrderRoll();
    };

    useEffect(() => {
        setup();
    }, []);

    // const colors = ["#f26a8d", "#b6fcd5", "#d84b6a", "#e4c027", "#8241a3", "#fd9e2a", "#5e4f1a", "#cde6f2", "#ac2b9e", "#71d35c", "#f0e8a1", "#429b4e", "#f58c19", "#637c8e", "#c31e3f", "#eb45b0", "#9f607b", "#24afef", "#f07fc5", "#3a7a89"];
    const colors = ["#f26a8d", "#CFBAF0", "#D08C60", "#e4c027", "#8241a3", "#fd9e2a", "#5e4f1a", "#cde6f2", "#3a7a89", "#71d35c", "#f0e8a1", "#429b4e", "#f58c19", "#637c8e", "#c31e3f", "#eb45b0", "#9f607b", "#24afef", "#f07fc5", "#3a7a89"];

    // add colors in flawType array
    useEffect(() => {
        
        const result = {};
        flawType.forEach(item => {
            const id = item[0];
            const name = item[1];
            const color = colors[id];
            const data = {
                id: id,
                name: name,
                color: color
            }
            result[id] = data;
        });
        setFlawWColor(result);
    }, [flawType]);


    useEffect(() => {
        /* 
        * if shown roll change call websocket for new roll
        * and update new flaw data for this roll
        */
        if (currentRoll != null){
            webSocket && webSocket.socket.close();  // close socket connection 
            callWebsocket(currentRoll.id);  // call new web socket connetion for current roll
        }
    }, [currentRoll]);


    const handleRollDelete = async (instance) => {
        /*
            * delete current roll
            * if we have selected roll,
            * first change the roll status as False.
            * If ve have active roll on this order,
            * set kg and, length as 0 and set status false,
            * Then check machine, if there is a selected or active machine,
            * stop the machine service.
        */

         // set roll status false
        if (currentRoll && currentRoll.status) {
            const deactivateRoll = await DeactivateRoll(currentRoll.id)
        }

        const machine = !isEmptyArray(activeMachine) ? activeMachine[0] : false;
        // stop machine if is run

        let machineCheck = !machine; // set machine check by default according to active machine
        if (machine) {
            // first store last active machine in session storage
            sessionStorage.setItem(`order_${id}`, JSON.stringify(machine));
            const machineRes = await stopMachineAPI(machine.id);
            machineCheck = machineRes.request.status === 200;
        }

        // check machine is stopped
        if (!machineCheck) {
            SetAlert({
                message: "Top silinirken bir hata oluştu, daha sonra tekrar deneyiniz!",
                type: "danger"
            });
            setToggle(false);
            return;
        }

        
        const response = await DeleteRoll(instance.id);
        if (response.request.status === 204) {
            SetAlert({
                message: "Top başarıyla silindi!",
                type: "success"
            });
            window.location.reload();  // refresh page
        }
        else if (response.request.status === 403) {
            // if response is 403, its mean current roll is the last roll of order
            // so we can not delete that roll
            SetAlert({
                message: `Sipariş üzerindeki son top silinemez.
                    Eğer gerçekten gerekliyse lütfen siparişi siliniz!`,
                type: "danger"
            })
        }
        else {
            SetAlert({
                message: "Top silinirken bir hata oluştu, daha sonra tekrar deneyiniz!",
                type: "danger"
            });
        };
        setToggle(false);  // close popup
       
    };

    const getClearImage = (image) => {
        // if image have full url of image,
        // clear url and just get image path like that /images/....
        try {
            const image_path = image.split('/');
            const protocol = image_path[0];
            if (protocol !== 'http' || protocol !== 'https') {
                // get last 3 index
                const image = `/${image_path[image_path.length - 3]}/${image_path[image_path.length - 2]}/${image_path[image_path.length - 1]}`;
                return image;
            }
            return image;
        }
        catch (error) {
            console.error(error);
            return image;
        }
    }

    const editRollToggle = (event, id) => {
        /**   
         * when toggle open first clear previous roll id and 
         * then get new order id & set new
        */
        event.preventDefault();
        setRollId(id);
        setEditRollModal(!editRollModal);
    };

    const handleEditRollSubmit = async (event) => {
        /**
         * When edit form submitted, this function will be called
         * and the roll meter will be updated
         * finally modal will be closed
         * and roll meter will be fetched again
         */
        event.preventDefault();
        // get input data
        const inputMeter = event.target[0].value;
        setRollFeedBack(true);
        // check input data
        if (inputMeter !== ''){
            const response = await UpdateRoll(rollId, inputMeter);
            try{
                const statusCode = response.request.status;
                if (statusCode === 200) { // success status
                    setCurrentRoll(prev => ({ ...prev, meter: inputMeter })); // update meter value in orderInfo
                }
            }
            catch (err){
                // console.log(err);
            }
            finally{
                setEditRollModal(!editRollModal);
            };
        };
    };

    return (
        <Container fluid>
            {/* LOADER */}
            <div className="loader" 
                 style={{background: '#a5a5a5cf', position: 'absolute', 
                         top: '0', right:'0', left: '0', bottom: '0', 
                         zIndex: '1000000', display: loader ? 'block': 'none',
                         overflow: 'hidden'}}>
                <div className="loader-animation" style={{position: 'relative', top: '50%', left: '50%', color:'#00425a'}}>
                    <Triangle 
                        height={64}
                        width={64}
                        color={'#00425a'}
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                    wrapperClassName=""
                    />
                </div>
                <p style={{position: 'relative', top: '50%', left: '50%', translate: '-20px', transform: 'translateY(10px)', color:'#00425a'}}>
                    {t('loading')}
                </p>
            </div>

            <Modal centered={true} isOpen={toggle}>
                <ModalHeader toggle={modalToggle}>{t('areYouSure')}</ModalHeader>
                <ModalBody>
                    <span>
                        <b>{t('rollDeleteWarning')}</b>
                    </span>
                </ModalBody>
                <ModalFooter>
                {/* check the machine is already active or not  */}
                    <Button color="warning"
                        onClick={() => handleRollDelete(currentRoll)}>
                        {t('approve')}
                    </Button>
                </ModalFooter>
            </Modal>

            {
            reminder && (
                <UncontrolledAlert 
                    color={reminder.type}
                    isOpen={reminderToggle}
                    toggle={handleReminderToggle}
                    style={{position: 'sticky', top: '4.5vw', right: '1%', width: '20%', float: 'right', zIndex: 5000}}>
                    {reminder && reminder.message}
                </UncontrolledAlert>
                )
            }

            <div className="page-content">
                <Row>
                    <Col xs={12} sm={12} md={2} lg={2}>
                        <OrderInfo data={data} currentRoll={currentRoll} editRollToggle={editRollToggle} t={t}/>
                        <FlawType flawWColor={flawWColor}
                                  order_id={id}
                                  roll={currentRoll}
                                  flaw={flaw}
                                  setFlaw={setFlaw}
                                  setFlawFilterToSession={setFlawFilterToSession}
                                  setPage={setPage}
                                  t={t}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={10} lg={10}>
                        <OrderGeneral flaw={flaw} rollCount={rollCount} t={t}/>
                        
                        <Container fluid>
                            {/* ALERTS */}
                            {
                                alert && (
                                <div 
                                    className={alert && `alert alert-${alert.type} alert-dismissible fade show text-center`} 
                                    role="alert" onClick={() => SetAlert(false)}>
                                    <strong >{alert.message}</strong> 
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                                )
                            }
                        
                            <Card className="m-0" style={{boxShadow:'none'}}>
                                <CardHeader style={{borderBottom: '1px solid #8080801a', padding: '0.5rem'}}>
                                    <Row className="text-center justify-content-center align-items-center" >
                                        <span className="fs-4 fw-bold text-primary" style={{position: 'relative'}}>
                                            { currentRoll && currentRoll.roll_count_of_order}. {t('roll')}
                                            <Button 
                                                // color='outline-danger' 
                                                className="btn-sm"
                                                style={{
                                                    position: 'absolute', 
                                                    right: '20px', top: '0', 
                                                    bottom: '0', width: '10%', 
                                                    padding: '0px 13px', maxWidth: '10%',
                                                    margin: '0', backgroundColor: '#6e1423',
                                                    border: '1px solid #641220',
                                                    alignItems: 'center', textAlign: 'center'
                                                    }}
                                                    onClick={() => modalToggle()}>
                                                {/* <i className="bx bxs-trash fs-5 text-danger" ></i> */}
                                                {t('rollDelete')}
                                            </Button>
                                        </span>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={5}>
                                            <h4 className=" text-uppercase mb-3 text-center">{t('flawMap')}</h4>
                                            <FlawMap
                                                flaws={flaw} 
                                                flawWColor={flawWColor}
                                                currentRoll={currentRoll}
                                                getClearImage={getClearImage}
                                                t={t}
                                            />
                                        </Col>
                                        
                                        <Col xs={12} sm={12} md={12} lg={7}>
                                                <FlawList 
                                                    machine={activeMachine}
                                                    action={action}
                                                    roll = {roll && roll} 
                                                    changeCurrentRoll={changeCurrentRoll}
                                                    active_roll={activeRoll} 
                                                    getActiveRoll={getActiveRoll}
                                                    loaderToggle={loaderToogle} 
                                                    forceRemoveLoader={forceRemoveLoader}
                                                    setOrderSession={setOrderSession}
                                                    t={t}
                                                />
                                                <FlawTable 
                                                    flaw={flaw} 
                                                    active_roll={activeRoll} 
                                                    page={page}
                                                    setPage={setPage}
                                                    currentRoll={currentRoll}
                                                    getClearImage={getClearImage}
                                                    t={t}
                                                />
                                        </Col>
                                    </Row>                                                        
                                </CardBody>
                            </Card>
                        </Container>
                    </Col>
                </Row>              
            </div>

            {/* edit roll  modal */}
            <Row>
                <Col>
                <Modal isOpen={editRollModal} toggle={editRollToggle} centered={true}>
                        <ModalHeader toggle={editRollToggle} >{t('updateRollMeter')}</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={handleEditRollSubmit}>
                                    <FormGroup>
                                        <Label for="roll_meter">
                                            {t('finishRollMeter')}
                                        </Label>

                                        <Input
                                            type="number"
                                            name="roll_meter"
                                            id="roll_meter"
                                            placeholder="Top metresi..." 
                                            style={{border: '1px solid #80808047', borderRadius: '3px', disabled: true}} 
                                            min="1"
                                            onChange={(e) => setRollMeter(e)}
                                            invalid={(showRollFeedBack && rollMeter === '' || rollMeter === null )}
                                        />
                                        { showRollFeedBack && rollMeter==='' 
                                            &&
                                            <FormFeedback invalid>
                                                {t('noEmptyRollMeterWarning')}
                                            </FormFeedback>
                                        }
                                        
                                    </FormGroup>
                                    <Button color="warning" type="submit" disabled={rollMeter === '' || rollMeter === null ? true : false} >
                                        {t('update')}
                                    </Button>
                                </Form>
                            </ModalBody>
                    </Modal>
                </Col>
            </Row>
        </Container>
    );
};
export default OrderDetail;
