import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Machine from '../pages/Machine';
import Order from '../pages/Order';
import OrderDetail from '../pages/OrderDetail';
import Profile from '../pages/Auth/Profile';
import Settings from '../pages/Settings/Settings';
import AdminPanel from '../pages/AdminPanel';
import ReportPanel from "../pages/ReportPanel";


const publicRoutes = [
    { path: "login", component: <Login /> },
]

const privateRoutes = [
    { path: "order", component: <Order />},
    { path: "order/:id", component: <OrderDetail />},
    { path: "profile", component: <Profile />},
    { path: "settings", component: <Settings />},
    { path: "report", component: <ReportPanel />}
]

const topSecretRoutes = [
    { path: "/", component: <p>Redirect</p>},
    { path: "dashboard", component: <Dashboard />},
    { path: "machine", component: <Machine />},
    { path: "permission", component: <AdminPanel />}
]




export { publicRoutes, privateRoutes, topSecretRoutes };