import { Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import BarCharts from "../../Layout/charts";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import MachineTable from "../../Layout/tables/MachineStatusTable";
import { DecryptToken } from "../../api/helpers/decrypt";
import { useTranslation } from 'react-i18next';

const token = DecryptToken();

const DashboardEcommerce = () => {
  const [order, setOrder] = useState([]);
  const [roll, setRoll] = useState([]);
  const [flaw, setFlaw] = useState([]);
  const [millimeter, setMillimeter] = useState([]);
  const { t } = useTranslation(['dashboard']); // Using the 'dashboard' namespace


  useEffect(() => {

       axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/report/all/`,
          headers: {
              'Authorization': token
          }
        }).then((response) => {
            const orderCount = response.data.data.order_count;
            const rollCount = response.data.data.roll_count;
            const flawCount = response.data.data.flaw_count;
            const millimeter = response.data.data.order_millimeter_sum;
            setOrder(orderCount);
            setRoll(rollCount);
            setFlaw(flawCount);
            setMillimeter(millimeter);
        }).catch((error) => {
            window.location.href = '/auth/login';
            setOrder('ERROR');
            setRoll('ERROR');
            setFlaw('ERROR');
            setMillimeter('ERROR');
      });
  });




  document.title ="Fralz Dashboard";

  

  return (
    <React.Fragment>
      <div className="page-content">

        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Widget order={order} roll={roll} flaw={flaw} millimeter={millimeter} t={t} />
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid>
            <BarCharts t={t} />
        </Container>

        <Container fluid>
            <MachineTable t={t} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
