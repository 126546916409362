import axios from "axios";
import {DecryptToken} from "../helpers/decrypt";

const GetRollMetric = async (id) => {
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/rollmetric/${id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': DecryptToken()
            }
        }).then((response) => {
            resolve(response);
        }
        ).catch((error) => {
            reject(error);
        }
        )
    });

    try {
        const response = await api;
        return response;
    }
    catch (e) {
        return e;
    }
}

export { GetRollMetric };